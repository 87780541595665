import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {adminSlice} from "../app/modules/Application/_redux/admin/adminSlice";
import {usersSlice} from "../app/modules/Application/_redux/users/usersSlice";
import {rolesSlice} from "../app/modules/Application/_redux/roles/rolesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  admin: adminSlice.reducer,
  users: usersSlice.reducer,
  roles: rolesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
