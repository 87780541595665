import axios from "axios";
import helper_funs from '../../../../js/helper_funs'

export const APIURL = process.env.REACT_APP_API_ENDPOINT;

export const LOGIN_URL = APIURL+"/auth";
export const REGISTER_URL = APIURL+"/register";
export const REQUEST_PASSWORD_URL = APIURL+"/forgot-password";

export const ME_URL = APIURL+"/me";
export const CREATE_ME_URL = APIURL+"/create_me";
export const SEND_VERIFICATION_CODE = APIURL+"/send_verification_code";

export async function login(email, password,user_type,auto_login = false) {

}

export async function register(data) {
 
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(data) {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(ME_URL,data);
}

export function createUserByToken(data) {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(CREATE_ME_URL,data);
}

export function createPassword(data){
  return axios.post(APIURL+'/create_password',data);
}

export function updateProfile(data){
  return axios.post(APIURL+'/update_profile',data);
}

export function updateProfilePhoto(data){
  return axios.post(APIURL+'/update_profile_photo',data);
}

export function verify_sms_code(data){
  return axios.post(APIURL+'/verify_sms_code',data);
}

export function send_verification_code(data){
  return axios.post(APIURL+'/send_verification_code',data);
}