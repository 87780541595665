/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers/index';
import * as adminCrud from '../../Application/_redux/admin/adminCrud';
import * as auth from '../_redux/authRedux';
import {
    LOGIN_URL, SIGNUP_URL
} from '../../../config';

export function Checkmembership() {

    const {authToken} = useSelector(state => state.auth);

    const history = useHistory()
    const dispatch = useDispatch();
    let useparams = useParams()
    let invitation_code = useparams.invitation_code

    const [exists,setExists] = useState(-1)

    const do_things = async (invitation_code) => {

        //first check if user is logged in or not
        if(authToken){

            let code = atob(invitation_code);
            //this code is json stringified
            let code_json = JSON.parse(code)
            let business_id = code_json.code;
            let data = {};
            data['business_id'] = business_id;

            let response = await adminCrud.switch_business(data);
            if(response.status){
                await dispatch(auth.actions.updateToken(response.data.token))
                window.location.href="/admin/index"
            }

        }
        else{
            //show login button
            setExists(1)
        }

    }

    const redirect_to_index = () =>{
        history.push("/admin/index");
    }

    useEffect(()=>{
        do_things(invitation_code)
    },[])

    return (
        <>


<div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                    id="kt_login"
                >

                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                        }}
                    >
                        {/*begin: Aside Container*/}
                        <div className="d-flex flex-row-fluid flex-column justify-content-between ">
                            {/* start:: Aside header */}
                            <Link to="/" className="flex-column-auto mt-5">
                                {/* <img
                      alt="Logo"
                      className="max-h-70px"
                      src={logo}
                  /> */}
                            </Link>
                            {/* end:: Aside header */}

                            {/* start:: Aside content */}
                            <div className="mt-2 flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 text-white mb-1">
                                    Welcome to Multi-Tanent System
                  </h3>
                            </div>
                            {/* end:: Aside content */}

                            {/* start:: Aside footer for desktop */}
                            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div className="opacity-70 font-weight-bold	text-white">
                                    &copy; {new Date().getFullYear()} MTMVP
                  </div>
                                <div className="d-flex">
                                    <Link to="/terms" className="text-white">
                                        Privacy
                    </Link>
                                    <Link to="/terms" className="text-white ml-10">
                                        Legal
                    </Link>
                                    <Link to="/terms" className="text-white ml-10">
                                        Contact
                    </Link>
                                </div>
                            </div>
                            {/* end:: Aside footer for desktop */}
                        </div>
                        {/*end: Aside Container*/}
                    </div>

                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative pb-0 pl-7 pt-7 pr-7 overflow-hidden">
                        {/* begin::Content body */}




                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <h2>{exists===-1 ? 'Processing new invatation. Please wait. . . .' : ''}</h2>
                
                {
                                exists===2 ?
                                    redirect_to_index()
                                :
                                ''
                            }

                            {exists===3 ? 
                            'You are already linked to the business'
                            :
                            ''
                            }

                {
                    exists===1 ?

                    <div className="login-form login-signin">
                        {/* begin::Head */}
                        <div className="text-center mb-10 mb-lg-10">
                            <h3 className="font-size-h1">Multi Tanent System</h3>
                            <div className="text-center">
                                <a className="mx-3" href={LOGIN_URL+"&state="+invitation_code} >
                                    <button className="px-8 my-1 btn btn-primary" color="primary" type="button">
                                        Sign In
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }

                        </div>
                        {/*end::Content body*/}

                        {/* begin::Mobile footer , right now hidden*/}
                        <div
                            className="d-none d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                                &copy; {new Date().getFullYear()} MTMVP
                </div>
                            <div className="d-flex order-1 order-sm-2 my-2">
                                <Link to="/terms" className="text-dark-75 text-hover-primary">
                                    Privacy
                  </Link>
                                <Link
                                    to="/terms"
                                    className="text-dark-75 text-hover-primary ml-4"
                                >
                                    Legal
                  </Link>
                                <Link
                                    to="/terms"
                                    className="text-dark-75 text-hover-primary ml-4"
                                >
                                    Contact
                  </Link>
                            </div>
                        </div>
                        {/* end::Mobile footer */}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>

        </>
    );
}
