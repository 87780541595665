import * as config from '../app/config';
import AWS from '../app/s3config';
import moment from 'moment';
const helper_funs = {
	
    convert_unixtimestamp_to_format(timestamp,format){
        let date = new Date(timestamp*1000);

        if(date){
            let year = date.getFullYear();
            let day = date.getDate();
      
            let day_string = ''
            if(day<10){
              day_string = "0"+day
            }
            else{
              day_string = day+""
            }
      
            let month = date.getMonth();
            //this month is index of months like for 10th month, it will return 9,so add 1 in it
            month = month+1;
      
            let month_string = ''
            if(month<10){
              month_string = "0"+month
            }
            else{
              month_string = month+""
            }

            let return_format = format
            return_format = return_format.replace('MM',month_string)
            return_format = return_format.replace('DD',day_string)
            return_format = return_format.replace('YYYY',year)

            return_format = return_format.replace('YY',year.toString().slice(-2))

            let hours = date.getHours()
            let minutes = date.getMinutes()
            
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'

            //append 0 with hour and minutes if these are 9,8,7,6,5,4,3,2,1,0
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;

            return_format = return_format.replace('hh',hours)
            return_format = return_format.replace('mm',minutes)
            return_format = return_format.replace('a',ampm)

            return return_format

        }
        return ''
    },

    convert_date_to_timestamp(date){
    
        date=date.split("-");
        var newDate=date[1]+","+date[2]+","+date[0];
        return Math.floor((new Date(newDate).getTime()/1000));
    
    },

    /**
     * gets s3 file name and returns public temp url for tha resource
     * @param {*} key 
     */

    get_signed_url(filename, setting){

		AWS.config.update({
			secretAccessKey: setting.S3_SECRET_KEY,
			accessKeyId: setting.S3_ACCESS_KEY,
			region: setting.S3_REGION
		});
        let signedUrlExpireSeconds = Number(setting.S3_SIGNED_URL_EXPIRYTIME)
        let s3 = new AWS.S3(); 	
        const url = s3.getSignedUrl('getObject', {
            Bucket: setting.S3_BUCKET,
            Key: filename,
            Expires: signedUrlExpireSeconds
        })

        return url
    },

    is_valid_json_string(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

	calculate_age_from_dob(dob){
		if(dob){
			let date = new Date(dob*1000)
			let year = date.getFullYear()

			let current_date = new Date();
			let current_year = current_date.getFullYear()

			return current_year-year
		}
		else{
			return 0
		}
	},

	calculate_age_from_dob2(dob){

		if(dob){
			let dob_splitted = dob.split("-")
			let year = parseInt(dob_splitted[0])
	
			let current_date = new Date();
			let current_year = current_date.getFullYear()
	
			return current_year-year
		}
		else{
			return 0
		}
	},

	get_id_from_location(location){
		let pathname = location.pathname

		//pathname e.g. /application/employees/LvMRcoAyvmhJr4sWepmFbfyAcGz1

		let id = ''
		let pathname_splitted = pathname.split('/')

		if(pathname_splitted.length===4){
			id = pathname_splitted[3]
		}
		
		return id
	},

	get_uri_params(location){

		if(!location.search) return {}

		let params = location.search
		params = params.replace("?","")
		params = params.replace("&&",'&')
		let params_splitted = params.split("&")

		let return_data = {}

		for(let i=0;i<params_splitted.length;i++){
			let param = params_splitted[i]

			let param_splitted = param.split("=")

			return_data[param_splitted[0]] = param_splitted[1]

		}
		return return_data

	},

	get_current_route(location){

		var pathname = location.pathname

		if(pathname.indexOf('login')>-1){
			return 'login'
		}
		else if(pathname.indexOf("index")>-1){
			return 'index'
		}
		else if(pathname==="" || pathname==="/" || pathname==="auth/" || pathname==='/auth/'){
			return 'index'
		}
		else if(pathname.indexOf("register")>-1 || pathname.indexOf("registration")>-1){
			return 'register'
		}
		else if(pathname.indexOf("forgot")>-1){
			return 'forgot_password'
		}
		else if(pathname.indexOf("error")>-1){
			return 'error'
		}
		else if(pathname.indexOf("logout")>-1){
			return 'logout'
		}
		else if(pathname.indexOf("create_password")>-1){
			return 'create_password'
		}
        else if(pathname.indexOf("authenticatemodule")>-1){
            return 'authenticatemodule'
        }
        else if(pathname.indexOf("restricted_access")>-1){
            return 'restricted_access'
        }
        else{

            //get route from location
            
            if(pathname.indexOf("application/")>-1){
                let pathname_splitted = pathname.split("/application/")
                let pathname2 = pathname_splitted[1]
    
                if(pathname2.indexOf("?")>-1){
                    pathname2 = pathname2.split("?")[0]
                }
    
                if(pathname2.indexOf("/")>-1){
                    pathname2 = pathname2.split("/")[0]
                }
    
                return pathname2;
            }
            
        }
	},

	//get pretty date from db datetime

	show_pretty_date_from_db_datetime(datetime){

		//datetime is local db time which sequelize has saved when task was created
		//convert it into date
		let date = datetime.split("T")[0]

		//now convert this date to date time object

		let date_object = new Date(date);

		//get timestamp from this date object in seconds
		let timestamp = (date_object.getTime()/1000)

		//format timestamp to your desired format with help of moment
		let pretty = moment.unix(timestamp).format('MMM DD, YY');

		return pretty

	},

	//get pretty date from time stamp

	get_pretty_date(timestamp){
		return moment.unix(timestamp).format('MMM DD, YY');
	},
	
	get_standard_format_datetime(timestamp){
		return moment.unix(timestamp).format('YY-MM-DD HH:mm:ss');
	},

	get_date_ymd_format(timestamp){
		return moment.unix(timestamp).format('YYYY-MM-DD');
	},

	//get error
    get_error_message(data){

        let message = 'Unknown error'
        if(data.hasOwnProperty('message') && data.message!==''){
            message = data.message
        }
        return message
	},
	
	validateEmployeeIDNumber(string){
		const re = /^[a-zA-Z0-9-_/]+$/
		return re.test(string);
	},

	validateName(name){
		const re = /^[a-zA-Z- ]+$/
		return re.test(name);
	},
	validateNumber(numb){
		const re = /^[0-9]+$/
		return re.test(numb);
	},
	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	},

	validateTelephone(phone){
		//format XXX-XXX-XXXX
		let phone_splitted = phone.split('-')
		if(phone_splitted[0].length!==3 || phone_splitted[1].length!==3 || phone_splitted[2].length!==4){
			return false
		}

		const re = /^[0-9]+$/

		if(!re.test(phone_splitted[0])){
			return false
		}

		if(!re.test(phone_splitted[1])){
			return false
		}

		if(!re.test(phone_splitted[2])){
			return false
		}

		return true

	},

	get24HourTime(time){
		let date = new Date(time * 1000);
		let hours = date.getHours()
		let ampm = ' AM'
		if(hours > 12){
			hours = hours - 12
			ampm = ' PM'
		}
		return hours+":"+date.getMinutes()+" "+ampm
	},
	getTimeToUnixTimeStamp(selectedTime){
		selectedTime = selectedTime.toString()
		let time_splitted = selectedTime.split(':')
		let newDate = new Date()
		newDate.setHours(time_splitted[0])
		newDate.setMinutes(time_splitted[1])
		newDate.setSeconds(0)
		newDate.setMilliseconds(0)
		let date = Math.floor((new Date(newDate).getTime()/1000));
		return date
	}

}

export default helper_funs;