/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

import { STRIPE_PLAN_INTERVAL } from './../../../../../app/config';



export function AsideMenuList({ layoutProps }) {


    const [roleLevel, setRoleLevel] = useState(-1);

    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    };
    const { user } = useSelector(state => state.auth);
    const admin = useSelector(state => state.admin);

    const showModulesList = () => {
        //lets find out this business is live or trial, if trial then check if trial has expired

        let business_status = admin.business_details.status

        let hide_modules = 0;

        if (business_status === 'trial') {

            //lets check trial is valid or is expired
            let business_create_date = admin.business_details.created_at
            let today_date = new Date()

            let daysDifference = get_date_difference_in_days(today_date, business_create_date)

            let stripe_plan_interval = STRIPE_PLAN_INTERVAL

            let allowed_days = 0
            if (stripe_plan_interval === 'day') {
                allowed_days = 1
            }
            else {
                allowed_days = 30
            }

            if (daysDifference > allowed_days) {
                hide_modules = 1
            }

        }
        let modules_list = admin.business_modules
        let user_status = 0
        if (user && user.hasOwnProperty('user_status'))
            user_status = user.user_status
        if (!hide_modules && modules_list.length && user_status === 1) {
            return modules_list.map((module, i) => {
                return (
                    <li
                        key={i}
                        className={`menu-item ${getMenuItemActive("/application/module/" + module.id)}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link" to={'/application/module/' + module.id} >
                            <i className="menu-bullet menu-bullet-dot">
                                <span />
                            </i>
                            <span className="menu-text text-capitalize">{module.name}</span>
                        </NavLink>

                    </li>

                )
            });
        }
        else {
            return ''
        }
    }
    const get_date_difference_in_days = (date1, date2) => {
        let business_created_timestamp = new Date(date2).getTime()
        let today_timestamp = date1.getTime();

        var difference = today_timestamp - business_created_timestamp

        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

        return daysDifference;

    }

    useEffect(() => {
        if (admin && admin.auth_data && admin.auth_data.hasOwnProperty('role_level')) {
            setRoleLevel(admin.auth_data.role_level)
        }
    }, [admin])

    return (
        <>
            <ul className="menu-nav">
                <li className="menu-section" style={{ height: "auto", marginTop: "-15px" }}><span className="menu-text" style={{ fontSize: "0.7rem" }}>You are logged in as: </span></li>
                <li className="menu-section" style={{ height: "auto", marginTop: "5px" }}><h4 className="menu-text text-capitalize"> {admin.business_details.name}</h4></li>
                {!(user && parseInt(user.is_user)) ?
                    <li className={`menu-item mt-2 ${getMenuItemActive("/admin/index")}`} aria-haspopup="true" >
                        <NavLink className="menu-link" to={admin.auth_data.business_id ? `/admin/index` : '/application/askforbusiness/0'}>
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")} />
                            </span>
                            <span className="menu-text">Dashboard</span>
                        </NavLink>
                    </li>
                    :
                    <></>
                }

                <li className="menu-section">
                    <h4 className="menu-text">Settings</h4>
                    <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
                </li>
                {!(user && parseInt(user.is_user)) ?
                    <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                        <a className="menu-link menu-toggle">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
                            </span>
                            <span className="menu-text">Business Settings</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="menu-submenu">
                            <i className="menu-arrow"></i>
                            <ul className="menu-subnav">
                                <li className="menu-item menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Pages</span>
                                    </span>
                                </li>

                                <li
                                    className={`menu-item ${getMenuItemActive("/application/addbusiness")}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/application/addbusiness">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span></span>
                                        </i>
                                        <span className="menu-text">Add New Business</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/application/mybusinesses")}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/application/mybusinesses">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span></span>
                                        </i>
                                        <span className="menu-text">My Businesses</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/application/allbusinesses")}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/application/allbusinesses">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span></span>
                                        </i>
                                        <span className="menu-text">All Businesses</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    :
                    <></>
                }
                {!(user && parseInt(user.is_user)) ?
                    <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                        <a className="menu-link menu-toggle">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")} />
                            </span>
                            <span className="menu-text">System Administration</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="menu-submenu">
                            <i className="menu-arrow"></i>
                            <ul className="menu-subnav">
                                <li className="menu-item menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Pages</span>
                                    </span>
                                </li>

                                {roleLevel > -1 && roleLevel < 2 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/departments")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/departments">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Departments</span>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/modules")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/modules">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Modules</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 2 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/roles")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/roles">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Roles</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 2 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/invitelinks")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/invitelinks">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Invite links</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 2 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/profiles")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/profiles">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Profiles</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 2 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/subscriptions")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/subscriptions">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Subscription</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 5 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/users")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/users">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Users</span>
                                        </NavLink>
                                    </li>
                                }
                                {roleLevel > -1 && roleLevel < 5 &&
                                    <li
                                        className={`menu-item ${getMenuItemActive("/application/sendkeys")}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/application/sendkeys">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Send User Keys</span>
                                        </NavLink>
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>
                    :
                    <></>
                }

                {(user && parseInt(user.is_user)) ?
                    <>
                        <li
                            className={`menu-item ${getMenuItemActive("/application/mybusinesses")}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/application/mybusinesses">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span className="menu-text">My Businesses</span>
                            </NavLink>
                        </li>

                        <li
                            className={`menu-item ${getMenuItemActive("/application/modules")}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/application/modules">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span className="menu-text">Modules</span>
                            </NavLink>
                        </li>
                    </>
                    :
                    <></>
                }
                <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                    <a className="menu-link menu-toggle">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                        </span>
                        <span className="menu-text">Profile Settings</span>
                        <i className="menu-arrow"></i>
                    </a>
                    <div className="menu-submenu">
                        <i className="menu-arrow"></i>
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Pages</span>
                                </span>
                            </li>
                            <li
                                className={`menu-item ${getMenuItemActive("/application/viewprofile")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/application/viewprofile">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span className="menu-text">View Profile</span>
                                </NavLink>
                            </li>
                            <li
                                className={`menu-item ${getMenuItemActive("/application/updateprofile")}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/application/updateprofile">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span className="menu-text">Update Profile</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>

                {/* <li className="menu-section">
									<h4 className="menu-text">Modules</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>	 */}

                {/* <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
									<a className="menu-link menu-toggle">
										<span className="svg-icon menu-icon">
											
                                        </span>
										<span className="menu-text">Modules List Show here</span>
 									</a>
									
                </li> */}

                {/* {showModulesList()} */}

            </ul>
        </>
    );
}
