/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useSelector} from "react-redux";
import helper_funs from '../../../../../js/helper_funs';

export function QuickUser() {
  const history = useHistory();
  const {user} = useSelector(state => state.auth);
  const {S3Setting} = useSelector(state => state.admin.setting);
  const user_details = useSelector(state => state.admin.user_profile);
  let user_complete_name =""
  let user_email =""
  let user_name =""
  if(user)  
  {
    user_complete_name=user.user_complete_name
    user_email=user.user_email
    user_name=user.user_name
  }
  
  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      history.push("/logout");
  };

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
            {/* { user.type==="employee" ?
            <a
              title="Edit profile"
              className="btn btn-icon btn-light btn-hover-primary btn-xs mx-3"
              onClick = { () => history.push("/application/employeeprofile") }
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                />
              </span>
            </a>
            :
            ''
            } */}
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
            className="offcanvas-content pr-5 mr-n5"
        >
        <a
          onClick = { () => history.push("/application/employeeprofile") }
          className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
        >
        {user_complete_name} ({user_name})
        </a>
          <div className="d-flex align-items-center mt-5">
            
            {
                user_details.image && user_details?.image !== '' ?
                <div className="symbol symbol-100 mr-5">
                    <div className="symbol-label" style={{
                        backgroundImage: `url(${ user_details && S3Setting && helper_funs.get_signed_url(user_details.image,S3Setting)})`,
                        backgroundSize:"contain",
                    }}/>
                    <i className="symbol-badge bg-success"/>
                </div>
                :
                <div className="symbol symbol-100 mr-5">
                    <div className="symbol-label" style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/default.jpg')})`,
                        backgroundSize:"contain",
                    }}/>
                    <i className="symbol-badge bg-success"/>
                </div>
            }

            <div className="d-flex flex-column">
              
              <div className="navi">
                <a className="navi-item">
                <span className="navi-link p-0 pb-1">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
				       {user_email}
                  </span>
                </span>
                </a>
              </div>
              <div className="navi">
                <a className="navi-item">
                <span className="navi-link p-0 pb-1">
                  <span className="navi-text text-muted text-hover-primary">
				       <b>Role: </b>{user?.role_name}
                  </span>
                </span>
                </a>
              </div>
              <div className="navi">
                <a className="navi-item">
                <span className="navi-link p-0 pb-1">
                  <span className="navi-text text-muted text-hover-primary">
				       <b>Department: </b>{user?.department_name}
                  </span>
                </span>
                </a>
              </div>
              <div className="navi">
                <a className="navi-item">
                <span className="navi-link p-0 pb-1">
                  <span className="navi-text text-muted text-hover-primary">
				       <b>Profile: </b>{user?.profile_name}
                  </span>
                </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>
        </div>
      </div>
  );
}
