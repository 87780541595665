/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import '../../../../_metronic/_assets/sass/pages/users/login-1.scss'
import { LOGIN_URL, SIGNUP_URL } from '../../../config'

export function AuthPage(props) {
	return (
		<>
			<div className='d-flex flex-column flex-root'>
				{/*begin::Login*/}
				<div
					className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white'
					id='kt_login'
				>
					{props.current_route !== 'register' ? (
						<div
							className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
							style={{
								backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
							}}
						>
							{/*begin: Aside Container*/}
							<div className='d-flex flex-row-fluid flex-column justify-content-between '>
								{/* start:: Aside header */}
								<a
									href='https://singlelogin.io'
									className='flex-column-auto mt-5'
								>
									<img
										alt='Logo'
										className='max-h-50px'
										src='/media/logos/white-logo.png'
									/>
								</a>
								{/* end:: Aside header */}

								{/* start:: Aside content */}
								<div className='mt-2 flex-column-fluid d-flex flex-column justify-content-center'>
									<h3 className='font-size-h1 text-white mb-1'>
										Welcome to Single Sign In
									</h3>
									<span className='text-white'>Multi Tenant System</span>
								</div>
								{/* end:: Aside content */}

								{/* start:: Aside footer for desktop */}
								<div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
									<div className='opacity-70 font-weight-bold	text-white'>
										&copy; {new Date().getFullYear()} SingleLogin
									</div>
									<div className='d-flex'>
										<a href='https://singlelogin.io' className='text-white'>
											Privacy
										</a>
										<a
											href='https://singlelogin.io'
											className='text-white ml-10'
										>
											Legal
										</a>
										<a
											href='https://singlelogin.io'
											className='text-white ml-10'
										>
											Contact
										</a>
									</div>
								</div>
								{/* end:: Aside footer for desktop */}
							</div>
							{/*end: Aside Container*/}
						</div>
					) : (
						''
					)}

					{/*begin::Aside*/}

					{/*begin::Content*/}
					<div className='flex-row-fluid d-flex flex-column position-relative pb-0 pl-7 pt-7 pr-7 overflow-hidden'>
						{/* begin::Content body */}
						<div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
							<div className='login-form login-signin'>
								{/* begin::Head */}
								<div className='text-center mb-10 mb-lg-10'>
									<h3 className='font-size-h1'>Single Sign In</h3>
									<div className='text-center'>
										<a className='mx-3' href={LOGIN_URL + '&state=0'}>
											<button
												className='px-8 my-1 btn btn-primary'
												color='primary'
												type='button'
											>
												Sign In As Business Manager
											</button>
										</a>

										<a className='mx-3' href={LOGIN_URL + '&state=1'}>
											<button
												className='px-8 my-1 btn btn-primary'
												color='primary'
												type='button'
											>
												Sign In As User
											</button>
										</a>
										{/* <a className="mx-3"
                            href={SIGNUP_URL}
                            >
                  <button className="px-8 my-1 btn btn-primary" color="primary" type="button">
                    Sign Up
                  </button>
                  </a> */}
									</div>
								</div>
							</div>
						</div>
						{/*end::Content body*/}

						{/* begin::Mobile footer , right now hidden*/}
						<div className='d-none d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
							<div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
								&copy; {new Date().getFullYear()} SingleLogin
							</div>
							<div className='d-flex order-1 order-sm-2 my-2'>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary'
								>
									Privacy
								</a>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary ml-4'
								>
									Legal
								</a>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary ml-4'
								>
									Contact
								</a>
							</div>
						</div>
						{/* end::Mobile footer */}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Login*/}
			</div>
		</>
	)
}
