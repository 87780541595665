import React, {Suspense} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/layout";
import '../css/custom.css';
import {DashboardPage} from "./pages/DashboardPage";

export default function BasePage(params) {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Redirect exact from="/" to="/dashboard"/>
                <Route path="/admin/index" component={DashboardPage}/>
            </Switch>
        </Suspense>
    );
}
