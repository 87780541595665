import {createSlice} from "@reduxjs/toolkit";
//import { actions } from "../../../Auth/_redux/authRedux";

const initialAdminsState = {
  business_details: {},
  business_list: [],
  auth_data: {},
  setting: {},
  user_profile: {},
  business_modules: [],
  modules_loading_status:-1,
  actionsLoading: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Set State Method
    setState: (state, action) => {

      if(action.payload.hasOwnProperty("business_details")){
          state.business_details = action.payload.business_details
      }

      if(action.payload.hasOwnProperty("business_list")){
        state.business_list = action.payload.business_list
      }

      if(action.payload.hasOwnProperty("auth_data")){
        state.auth_data = action.payload.auth_data
      }


      if(action.payload.hasOwnProperty("app_keys")){
        state.app_keys = action.payload.app_keys
      }
      
      if(action.payload.hasOwnProperty("user_profile")){
        state.user_profile = action.payload.user_profile
      }

      if(action.payload.hasOwnProperty("business_modules")){
        state.business_modules = action.payload.business_modules
      }

      if(action.payload.hasOwnProperty("modules_loading_status")){
        state.modules_loading_status = action.payload.modules_loading_status
      }
      if(action.payload.hasOwnProperty("setting")){
        state.setting = action.payload.setting
      }

    },
    // gettaskById
    taskFetched: (state, action) => {
      state.actionsLoading = false;
      state.taskForEdit = action.payload.taskForEdit;
      state.error = null;
    },
    // gettaskById
    userProfileFetched: (state, action) => {
      state.actionsLoading = false;
      state.user_profile = action.payload.data;
      state.error = null;
    },
   
    // createboard
    boardCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.board);
    },
    // updateboard
    boardUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.board.id) {
          return action.payload.board;
        }
        return entity;
      });
    },
    // deleteemployees
    testDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletetasks
    testsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // boardsUpdateState
    boardsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
