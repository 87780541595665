export const ENV = 'LIVE'

//export API_URL on the basis of ENV
export const API_URL = process.env.REACT_APP_API_URL

export const APP_BASE_URL = process.env.REACT_APP_URL

export const OWNER_PROFILE_ID = 2

export const API_IS_INVITE_LINK_USABLE = "is_invite_link_usable"
export const API_GET_JWT = "create_jwt"
export const API_GET_BUSINESS = "get_business"
export const API_ADD_BUSINESS = "add_business"
export const API_UPDATE_BUSINESS = "update_business"
export const API_GET_BUSINESS_MODULES = "get_modules"
export const API_ADD_PROFILE = "add_profile"
export const API_UPDATE_PROFILE = "update_profile"
export const API_GET_PROFILE_DETAILS = "get_profile_details"
export const API_GET_PROFILES = "get_profiles"
export const API_CHECK_USER_EXISTS = "check_user_exist"
export const API_DELETE_USER = "delete_user"
export const API_INVITE_USER = "invite_user"
export const API_GET_BUSINESS_DETAILS = "get_business_detail"
export const API_DELETE_PROFILE = "delete_profile"
export const API_SWITCH_BUSINESS = "switch_business"
export const API_DELETE_BUSINESS = "delete_business"
export const API_USERS_LIST = "users_list"
export const API_GET_ALL_USERS = "get_all_users"
export const API_CHANGE_USER_STATUS = "change_user_status"
export const API_SWITCH_USER_PROFILE = "switch_user_profile"
export const API_GET_MODULE_PERMISSION = "get_module_permission"
export const API_GET_MODULE_ACCESS = "module_access"
export const API_ADD_MODULE = "add_module"
export const API_UPDATE_MODULE = "update_module"
export const API_DELETE_MODULE = "delete_module"
export const API_INVITE_MULTIPLE_USERS = "invite_multiple_users"
export const API_GET_APP_KEYS = "get_app_keys"
export const API_GET_DEPARTMENTS = "get_departments"
export const API_ADD_DEPARTMENT = "add_department"
export const API_UPDATE_DEPARTMENT = "update_department"
export const API_DELETE_DEPARTMENT = "delete_department"
export const API_DEFAULT_MODULE = "default_modules"
export const API_GET_MODULES_HAVING_COST = "get_modules_having_cost"
export const API_GET_BUSINESS_ROLES = "get_business_roles"
export const API_GET_DEFAULT_ROLES = "get_default_roles"
export const API_GET_DEFAULT_PROFILES = "get_default_profiles"
export const API_ADD_UPDATE_ROLE = "add_update_role"
export const API_DELETE_ROLE = "delete_role"
export const API_CREATE_SUBSCRIPTION = "create_subscription"
export const API_CREATE_STRIPE_CHECKOUT_SESSION = "create_stripe_checkout_session"
export const API_CANCEL_SUBSCRIPTION = "cancel_subscription"
export const API_RESUME_PAUSE_SUBSCRIPTION = "resume_pause_subscription"
export const API_CREATE_STRIPE_CUSTOMER = "create_stripe_customer"
export const API_GET_ALL_BUSINESSES_LIST = "get_all_businessess_list"
export const API_SEND_BUSINESS_JOIN_REQUEST = "send_business_join_request"
export const API_GET_BUSINESS_REQUEST_LIST = "get_business_join_request_list"
export const API_GET_BUSINESS_MODULE_ID = "get_business_module_id"
export const API_REJECT_USER_REQUEST = "reject_user_request"
export const API_USER_UPLOAD_HISTORY = "get_user_upload_history"
export const API_GET_USER_PROFILE = "get_user_profile"
export const API_UPDATE_USER_PROFILE = "update_user_profile"
export const API_UPDATE_PROFILE_PHOTO = "update_profile_photo"
export const API_VIEW_USER_PROFILE = "user_view_profile"
export const API_ADD_UPDATE_INVITE_LINK = "add_update_invite_link"
export const API_GET_INVITE_LINK = "get_invite_link"
export const API_DELETE_INVITE_LINK = "delete_invite_link"
export const API_SAVE_REFERRAL_CODE = "save_referral_code"
export const API_CREATE_PAYSTACK_PLAN = "create_paystack_plan"
export const API_CREATE_PAYPAL_PLAN = "create_paypal_plan"
export const API_AFTER_PAYSTACK_SUBSCRIPTION = "after_paystack_subscription"
export const API_AFTER_PAYPAL_SUBSCRIPTION = "after_paypal_subscription"
export const API_GET_SYSTEM_COUNTS = "get_system_counts"
export const API_GET_COUPON_PERCENTAGE = "get_coupon_percentage"
export const API_GET_DASHBOARD_STATS = "get_dashboard_stats"
export const API_SEND_APP_KEYS = "send_app_keys_to_user"
export const API_GET_USER_MODULE_SETTING = "get_user_module_setting"

//Stripe keys, please also set API URL as live webhook URL in Stripe webhooks
export const STRIPE_PUBLISHABLE_KEY = "pk_test_0JG4x6MassxWc4vP6YZWDpx0"
export const STRIPE_PLAN_INTERVAL = "month" //day is for testing, normally it should be month

//Paystack, please also set API URL as live webhook URL in Paystack webhooks
export const PAYSTACK_PUBLIC_KEY = "pk_test_69fb947e3d3c5d0a8f833f1aa6ff3f10c7fa3a1e"

//PAYPAL, please also set API URL as live webhook URL in PAYPAL webhooks
export const PAYPAL_CLIENT_ID = "AUIaIFh56ScXJLcGGvU1Flw-b5OmZnMLMbs2ZlnVbwsYUyDAdrYi5AwEP184KL5wlHU98chxQ2wYG_ox"

export const MODULE_URL = 'http://localhost:3001/view'

export const SALT = '7sd!O(!@$*!#*#!a989!!@*#!@#&!^#*!&3hASD987*(#*%$&'

// OAUTH URL
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL

export const INVITE_BASE_URL = `${process.env.REACT_APP_URL}/auth/invite/`

//export REDIRECT_URI on the basis of ENV
export const REDIRECT_URI = `${process.env.REACT_APP_URL}/admin/index`

//Authenticate modules with these URLs
export const AUTHENTICATEMODULE_REDIRECT_URI = `${process.env.REACT_APP_URL}/auth/authenticatemodule`


export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET


// TOKEN
export const JWT_SECRET= "MTMVP JWT SECRET"

export const ADD_BUSINESS_URL= "/admin/add_business"
export const LOGOUT_URL= "/Auth/login"
export const LOCALSTORAGEKEY= process.env.REACT_APP_LOCALSTORAGEKEY
export const LOGIN_URL= process.env.REACT_APP_LOGIN_URL+REDIRECT_URI
export const SIGNUP_URL= process.env.REACT_APP_SIGNUP_URL+REDIRECT_URI

export const AUTHENTICATEMODULE_URL= process.env.REACT_APP_AUTHENTICATEMODULE_URL+AUTHENTICATEMODULE_REDIRECT_URI

export const ROUTES_ALLOWED_WITHOUT_BUSINESS = ["addbusiness","mybusinesses","allbusinesses","viewprofile","updateprofile"]
