import {createSlice} from "@reduxjs/toolkit";

const initialRolesState = {
  actionsLoading: false,
  businessRolesList: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState: initialRolesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Set State Method
    setState: (state, action) => {

      if(action.payload.hasOwnProperty("roles_list")){
          state.businessRolesList = action.payload.roles_list
      }

    }
  }
});
