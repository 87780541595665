/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from "react";
import objectPath from "object-path";
import {useLocation} from "react-router-dom";
//import {QuickActions} from "./components/QuickActions";
import {BreadCrumbs} from "./components/BreadCrumbs";
import {getBreadcrumbsAndTitle, useSubheader} from "../../_core/MetronicSubheader";
import {useHtmlClassService} from "../../_core/MetronicLayout"

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);
  let MainHeadingsList = {"Enter profile details and submit":"  Update Profile Information","My Businesses":"Business List","All Businesses":"Business List","Invite Links":"Business Invite Links", "Departments":"Business Departments","Roles":"Business Roles","Profiles":"Business Profiles","Dashboard":"Dashboard", "Add New Department":"Business Departments", "Add New Role":"Business Roles", "Create Invite Link":"Business Invite Links"}
  let MainHeadingsUrl = {"/application/createinvitelink":"Create Invite Link","/application/addrole":"Add New Role", "/application/adddepartment":"Add New Department", "/application/updateprofile":"Enter profile details and submit"}
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    // if(aside.title===''){aside.title="Dashboard"}
    if(aside.title==='' || aside.title === 'Update Profile'){
      aside.breadcrumbs[0] = {}
      aside.breadcrumbs[0].title = MainHeadingsUrl[location.pathname] 
      aside.title = MainHeadingsUrl[location.pathname] 
    }
    subheader.setBreadcrumbs(aside.breadcrumbs !== '' ? aside.breadcrumbs : header.breadcrumbs);
    subheader.setTitle(aside.title !== '' ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  

  return (
      <div
          id="kt_subheader"
          className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
      >
        <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
                <button
                    className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                    id="kt_subheader_mobile_toggle"
                >
                  <span/>
                </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2">
                <>
                  {MainHeadingsList[subheader.title]}
                </>
                <small></small>
              </h5>

            </div>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
        </div>
      </div>
  );
}
