/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const [roleLevel, setRoleLevel] = useState(-1);
    const admin = useSelector(state => state.admin);


    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }
    
    useEffect(() => {
        if(admin && admin.auth_data && admin.auth_data.hasOwnProperty('role_level')){
            setRoleLevel(admin.auth_data.role_level)
        }
    },[admin])

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/businesssettings')}`}>
                <NavLink className="menu-link menu-toggle" to="/custom">
                    <span className="menu-text">Business Settings</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">

                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/application/addbusiness')}`}>
                            <NavLink className="menu-link" to="/application/addbusiness">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Clothes/Briefcase.svg")} />
                                </span>
                                <span className="menu-text">Add Business</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/application/mybusinesses')}`}>
                            
                            <NavLink className="menu-link" to="/application/mybusinesses">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
                                </span>
                                <span className="menu-text">My Businesses</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/application/allbusinesses')}`}>
                            <NavLink className="menu-link" to="/application/allbusinesses">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/CMD.svg")} />
                                </span>
                                <span className="menu-text">All Businesses</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                <NavLink className="menu-link menu-toggle" to="/google-material">
                    <span className="menu-text">System Administration</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        {roleLevel > -1 && roleLevel < 2 &&
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/departments')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/departments">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Departments
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/adddepartment')}`}>
                                            <NavLink className="menu-link" to="/application/adddepartment">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Add New Department</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/departments')}`}>
                                            <NavLink className="menu-link" to="/application/departments">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">View All Departments</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}


                        {/*begin::2 Level*/}
                        {
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/modules')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/modules">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Modules
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}

                                    {
                                        roleLevel > -1 && roleLevel < 2 && 
                                        <li className={`menu-item ${getMenuItemActive('/application/addmodules')}`}>
                                            <NavLink className="menu-link" to="/application/addmodules">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Add New Module</span>
                                            </NavLink>
                                        </li>
                                    }

                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/modules')}`}>
                                            <NavLink className="menu-link" to="/application/modules">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">View All Modules</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}



                        {/*begin::2 Level*/}
                        {roleLevel > -1 && roleLevel < 2 &&
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/roles')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/roles">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Roles
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/addrole')}`}>
                                            <NavLink className="menu-link" to="/application/addrole">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Add New Role</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/roles')}`}>
                                            <NavLink className="menu-link" to="/application/roles">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">View All Roles</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}



                        {/*begin::2 Level*/}
                        {roleLevel > -1 && roleLevel < 2 &&
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/profiles')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/profiles">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Profiles
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/addprofile')}`}>
                                            <NavLink className="menu-link" to="/application/addprofile">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Add New Profile</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/profiles')}`}>
                                            <NavLink className="menu-link" to="/application/profiles">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">View All Profiles</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}



                        {/*begin::2 Level*/}
                        {roleLevel > -1 && roleLevel < 2 &&
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/subscriptions')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/subscriptions">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Subscriptions
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/addsubscription')}`}>
                                            <NavLink className="menu-link" to="/application/addsubscription">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Add New Subscription</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/subscriptions')}`}>
                                            <NavLink className="menu-link" to="/application/subscriptions">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">View All Subscriptions</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}



                        {/*begin::2 Level*/}
                        {roleLevel > -1 && roleLevel < 5 &&
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/application/users')}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link menu-toggle" to="/application/users">
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                                    </span>
                                    <span className="menu-text">
                                        Users
                                    </span>
                                    <i className="menu-arrow" />
                                </NavLink>
                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                    <ul className="menu-subnav">
                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/inviteusers')}`}>
                                            <NavLink className="menu-link" to="/application/inviteusers">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Invite User</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        <li className={`menu-item ${getMenuItemActive('/application/uploadusers')}`}>
                                            <NavLink className="menu-link" to="/application/uploadusers">
                                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                <span className="menu-text">Upload Users</span>
                                            </NavLink>
                                        </li>
                                        {/*end::3 Level*/}

                                        {/*begin::3 Level*/}
                                        {roleLevel > -1 && roleLevel < 2 &&
                                            <li className={`menu-item ${getMenuItemActive('/application/invitelinks')}`}>
                                                <NavLink className="menu-link" to="/application/invitelinks">
                                                    <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                    <span className="menu-text">Create Invitation Link</span>
                                                </NavLink>
                                            </li>
                                        }
                                        {/*end::3 Level*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        {/*end::2 Level*/}

                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
