/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

export function LoadingModule() {
    return (
        <>
            <div id="loading_module_div" className="d-flex align-items-center justify-content-center w-100 h-100 flex-column">
                <div style={{width:'110px',height:'110px'}} className="spinner-border text-primary"></div>
                <h4 className="text-muted mt-2">Loading user profiles & configuration</h4> 
                {/* <svg className="splash-spinner" viewBox="0 0 100 100">
                    <circle className="path"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    strokeWidth="5"
                    ></circle>
                </svg> */}
            </div>
        </>
    );
}
