import {createSlice} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken,createUserByToken } from "./authCrud";
import {LOCALSTORAGEKEY} from '../../../config'
let request_data = {}


export const actionTypes = {
  Login: "[Login] Action",
  UpdateToken: "[UpdateToken] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserCreated: "[Created User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  message:'',
  status:'',
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    dataFetched: (state, action) => {
      const { user,authToken } = action.payload;
      state.user = user;
      state.authToken = authToken;
    },
  }
});

export const reducer = persistReducer(
  { storage, key: LOCALSTORAGEKEY, whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      
      case actionTypes.UpdateToken: {
        const authToken  = action.payload.authToken;
        return { ...state , authToken};
      }
      case actionTypes.UpdateUser: {
        const user  = action.payload.user;
        
        return { ...state , user};
      }

      case actionTypes.Login: {
        request_data = {
            user_type:action.payload.authToken.new_data.user_type,
            uid:action.payload.authToken.new_data.uid,
            action:'login'
        }

        const authToken  = action.payload.authToken.new_data.accessToken;
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {

        const authToken  = action.payload.authToken.authToken;

        request_data = {
          data:action.payload.authToken,
          action:'register'
        }

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      
      case actionTypes.UserCreated: {

        const message  = action.payload.data.message;
        const status = action.payload.data.status;
        return { ...state, message,status };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  updateToken: authToken => ({ type: actionTypes.UpdateToken, payload: { authToken } }),
  updateUser: user => ({ type: actionTypes.UpdateUser, payload: { user } }),
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  userCreated: data => ({ type: actionTypes.UserCreated, payload: { data } })
};

export function* saga(data) {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
	
  });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    if(request_data.action==="login"){
        const { data: user } = yield getUserByToken(request_data);
        yield put(actions.fulfillUser(user));
    }
    else{
      const response = yield createUserByToken(request_data);

        yield put(actions.userCreated(response.data));
    }
    
  });
}
