import * as requestFromServer from "./adminCrud";
import {adminSlice, callTypes} from "./adminSlice";

const {actions} = adminSlice;
 

export const createEmployee = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  
};

export const fetchTest = ids => dispatch => {

}

// export const fetchTests = queryParams => dispatch => {
//   let tasks_reponse = actions.startCall({ callType: callTypes.list })
//   dispatch(tasks_reponse);
//   return requestFromServer
//     .findTests(queryParams)
//     .then(response => {
// 	//I have to change API response as per client reuest so here i hvae to change as well.
//       const { totalCount,totalPages, entities } = response.data.data;
//       dispatch(actions.testsFetched({ totalCount,totalPages, entities }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't find tests";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const deleteTests = ids => dispatch => {
//     dispatch(actions.startCall({ callType: callTypes.action }));
//     return requestFromServer
//     .deleteTests(ids)
//     .then(() => {
//       dispatch(actions.testsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete tests";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// }

export const fetchUserProfile = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .view_user_profile(queryParams)
    .then(response => {
      const data = response.data.data;
      dispatch(actions.userProfileFetched({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
